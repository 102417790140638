import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { SEO } from "components";
import BlockContent from "@sanity/block-content-to-react";
import styles from "./information.module.scss";

const InformationPage: React.FC = () => {
  const { allSanityAbout, allSanityReadingList } = useStaticQuery(graphql`
    query InformationPageData {
      allSanityAbout {
        edges {
          node {
            _rawContent
          }
        }
      }
      allSanityReadingList {
        edges {
          node {
            books {
              id
              title
              author {
                name
              }
              cover {
                asset {
                  fluid(maxWidth: 1000) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const aboutContent = allSanityAbout.edges[0].node._rawContent;
  const readingList = allSanityReadingList.edges[0].node.books;

  const openMail = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    window.location.href = "mailto:alec@alecmarc.us";
    (e.target as HTMLButtonElement).blur();
  };

  return (
    <div className={styles.pageWrapper}>
      <SEO title="Information" />
      <main className={styles.main}>
        <BlockContent
          className={styles.aboutContent}
          blocks={aboutContent}
          dataset="production"
          projectId="jcmgoa34"
          renderContainerOnSingleChild={true}
          serializers={{ container: "section" }}
        />
      </main>
      <aside className={styles.aside}>
        <section>
          <h2 className={styles.contactHeading}>Contact</h2>
          <ul className={styles.contactList}>
            <li className={styles.emailWrapper}>
              <button
                className={styles.emailButton}
                onClick={(e): void => openMail(e)}
              >
                Email
              </button>
            </li>
            <li>
              <a
                href="https://github.com/alecmarcus"
                target="_blank"
                rel="noopener noreferrer"
              >
                GitHub
              </a>
            </li>
          </ul>
        </section>
        <section>
          <h2>Reading</h2>
          <ul className={styles.readingList}>
            {readingList.map((book) => (
              <li className={styles.book} key={book.id}>
                <h3 className={styles.bookTitle}>{book.title}</h3>
                <cite className={styles.bookAuthor}>{book.author.name}</cite>
              </li>
            ))}
          </ul>
        </section>
      </aside>
    </div>
  );
};

export default InformationPage;
